@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.player :global {

    .bmpui-ui-watermark {
        display: none !important;
    }

    // play seekbar
    .bmpui-seekbar-bufferlevel {

        background-color: $primary !important;
    }

    .bmpui-seekbar-seekposition {
        background-color: $primary !important;
    }

    .bmpui-seekbar-playbackposition {

        background-color: $primary !important;
    }

    .bmpui-seekbar-playbackposition-marker{
        background-color: $primary !important;
        border: solid $primary !important;
    }


    // volume bar
    .bmpui-ui-volumeslider .bmpui-seekbar-seekposition{
        background-color: $primary !important;
    }

    .bmpui-ui-volumeslider .bmpui-seekbar-playbackposition{
        background-color: $primary !important;
    }

    .bmpui-ui-volumeslider .bmpui-seekbar-playbackposition-marker{
        background-color: $primary !important;
    }

    // buttons

    .bmpui-ui-fullscreentogglebutton.bmpui-on{
        background-image: url('../../assets/images/fullscreeenIcon.svg');
    }

    .bmpui-ui-settingstogglebutton.bmpui-on {
        background-image: url('../../assets/images/config.svg');
    }

    // settu=ing panel
    .bmpui-ui-settings-panel-item .bmpui-ui-selectbox{
        color: $primary ;
    }
    .bmpui-ui-settings-panel-item .bmpui-ui-selectbox option:hover {
        background-color: $primary !important;
    }

}