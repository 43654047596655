@import "@dex/bubl-web/src/styles/Global.scss";

$primary: #ff4e39;
$secondary: #5f7a9e;
$alt: #f2c94c;

$primaryLight: #f5e8e6;
$primaryDark: #02004a;

$light0: white;
$light1: #f9f9f9;
$light2: #eff2f6;
$light3: #dfe4ea;
$light4: #c6c6c8;
$light5: #bfbfbf;
$light6: #bdbdbd;

$dark0: black;
$dark1: #383b3c;
$dark2: #595c5e;
$dark3: #6f7781;

$purpleHl: #3d5afe;
$yellowHl: #ffc107;
$orangeHl: #ff5722;
$greenHl: #27AE60;
$blueHl: #2F80ED;
$pinkHl: #ff00dd;
$redHl: #f5222d;
$followBtn: #ffbc39;

$bodyBg: $light2;

$modalBg: rgba($dark0, 0.5);

$panelBg: $light0;
$panelBdr: $light3;
$panelText: $bodyText;

$fonts: (
    "primary": unquote("Poppins,sans-serif"),
    "heading": unquote("Montserrat,sans-serif"),
);

$fontSize: (
    "tiny": 11px,
    "small": 13px,
    "normal": 15px,
    "medium": 18px,
    "large": 22px,
    "xl": 24px,
    "xxl": 28px,
);



@mixin headingText($family: "heading", $weight: 500, $size: "medium", $alt: ()) {
    font-family: map-get($fonts, $family);
    font-weight: $weight;
    font-size: map-get($fontSize, $size);
    // line-height: map-get($fontSize, $size) * 1.5;

    @if (map-get($alt, "decoration")) {
        text-decoration: map-get($alt, "decoration");
    }

    @if (map-get($alt, "style")) {
        font-style: map-get($alt, "style");
    }

    @if (map-get($alt, "color")) {
        color: map-get($alt, "color");
    }
}

@mixin bodyText($family: "primary", $weight: 400, $size: "small", $alt: ()) {
    font-family: map-get($fonts, $family);
    font-weight: $weight;
    font-size: map-get($fontSize, $size);
    line-height: map-get($fontSize, $size) * 1.5;

    @if (map-get($alt, "decoration")) {
        text-decoration: map-get($alt, "decoration");
    }

    @if (map-get($alt, "style")) {
        font-style: map-get($alt, "style");
    }

    @if (map-get($alt, "color")) {
        color: map-get($alt, "color");
    }
}